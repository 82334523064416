// create slug function
let url = window.location.pathname
function deUmlaut(value) {
  value = value.toLowerCase()
  value = value.replace(/ä/g, 'ae')
  value = value.replace(/ö/g, 'oe')
  value = value.replace(/ü/g, 'ue')
  value = value.replace(/ß/g, 'ss')
  value = value.replace(/ /g, '-')
  value = value.replace(/\./g, '')
  value = value.replace(/,/g, '')
  value = value.replace(/\(/g, '')
  value = value.replace(/\)/g, '')
  value = value.replace(/:/g, '')
  value = value.replace(/\//g, '-')
  return value
}

// get Safe undefined Values
function getSafe(fn, defaultVal) {
  try {
    return fn()
  } catch (e) {
    return defaultVal
  }
}

// compare arrays function
function arrayContainsArray(superset, subset) {
  if (0 === subset.length) {
    return false
  }
  return subset.every(function(value) {
    return superset.indexOf(value) >= 0
  })
}

// Timout for display block
function configureTimeoutBlock(i) {
  setTimeout(function() {
    i.style.display = 'flex'
  }, 500)
}

// Timout for display none
function configureTimeoutNone(i) {
  setTimeout(function() {
    i.style.display = 'none'
  }, 500)
}

// actual filter function
function filterAngebote(
  angeboteVergleichsObjekt,
  currentSelection,
  usedSearchFilter
) {
  var allAngebote = document.querySelectorAll('.angebote-container'),
    i,
    counter = 0

  if (currentSelection == 0) {
    if (usedSearchFilter) {
      for (i = 0; i < allAngebote.length; ++i) {
        allAngebote[i].style.opacity = '0'
        configureTimeoutNone(allAngebote[i])
      }
      angeboteVergleichsObjekt.forEach(angebot => {
        let safeAngebot = getSafe(() => angebot[0].Item)
        if (safeAngebot !== undefined) {
          safeAngebot.parentNode.parentNode.parentNode.parentNode.style.opacity =
            '1'
          configureTimeoutBlock(
            safeAngebot.parentNode.parentNode.parentNode.parentNode
          )
          counter++
        }
      })
    } else {
      for (i = 0; i < allAngebote.length; ++i) {
        allAngebote[i].style.opacity = '1'
        configureTimeoutBlock(allAngebote[i])
        counter++
      }
    }
  } else {
    for (i = 0; i < allAngebote.length; ++i) {
      allAngebote[i].style.opacity = '0'
      configureTimeoutNone(allAngebote[i])
    }
    angeboteVergleichsObjekt.forEach(angebot => {
      var finalAngeboteVergleichsObjekt = angebot.map(function(obj) {
        return obj.Value
      })

      if (arrayContainsArray(finalAngeboteVergleichsObjekt, currentSelection)) {
        let safeAngebot = getSafe(() => angebot[0].Item)
        if (safeAngebot !== undefined) {
          angebot[0].Item.parentNode.parentNode.parentNode.parentNode.style.opacity =
            '1'
          configureTimeoutBlock(
            angebot[0].Item.parentNode.parentNode.parentNode.parentNode
          )
          counter++
        }
      }
    })
  }

  const counterNode = document.getElementById('anzahl-gefundene-angebote')

  counterNode.innerHTML = counter
  if (counter == 0) {
    document.querySelector('.keine-ergebnisse').style.display = 'block'
  } else {
    document.querySelector('.keine-ergebnisse').style.display = 'none'
  }
}

// Checkbox-colors function
function changeCheckBoxColor(input) {
  input.checked
    ? (input.parentNode.style.backgroundColor = '#B8D3C0')
    : (input.parentNode.style.backgroundColor = 'white')
}

if (url == '/angebote/') {
  // Define Const
  const catCheckBoxNode = document.querySelectorAll(
    '.detailsuche .categories input'
  )
  catCheckBoxNode.autofocus = false
  const categoryAllCheckbox = document.querySelectorAll(
    '.detailsuche .alle input'
  )
  const categoryCheckbox = Array.from(catCheckBoxNode)
  const angebote = Array.from(document.querySelectorAll('.angebote-container'))
  const angebotKategorien = angebote.map(el =>
    el.querySelectorAll('.category-tags li')
  )
  console.log(angebotKategorien)
  const angeboteVergleichsObjekt = angebotKategorien.map(angebot =>
    Array.from(angebot).map(categoryitem => {
      return {
        Value: deUmlaut(categoryitem.innerText),
        Category: deUmlaut(
          categoryitem.parentNode.previousElementSibling.innerText
        ),
        Item: categoryitem,
      }
    })
  )

  var currentSelection = []

  // Onclick Search-Filter
  var searchbtn = document.querySelector('.searchbtn')
  searchbtn.addEventListener('click', function() {
    event.preventDefault()
    const angeboteErgebnisNode = document.querySelectorAll('.angebote-inhalte')
    const angeboteErgebnis = Array.from(angeboteErgebnisNode)
    let inputText = event.target.parentNode.querySelector('input').value
    let inputTextSlug = deUmlaut(inputText)
    let SearchPostIds = []
    for (let i = 0; i < angeboteErgebnis.length; i++) {
      let textContentSlug = deUmlaut(angeboteErgebnis[i].textContent)
      if (textContentSlug.indexOf(inputTextSlug) != -1) {
        SearchPostIds.push(angeboteErgebnis[i].parentNode.getAttribute('value'))
      }
    }

    let angeboteVergleichsObjektmitSuchfilter = SearchPostIds.map(
      item => angeboteVergleichsObjekt[item]
    )

    filterAngebote(
      angeboteVergleichsObjektmitSuchfilter,
      currentSelection,
      true
    )
  })

  // On Keyup Search-Field, trigger click on Search-Button
  var input = document.getElementById('searchbar')
  input.addEventListener('keyup', function(event) {
    if (event.keyCode === 13) {
      event.preventDefault()
      document.querySelector('.searchbtn').click()
    }
  })

  // onclick "all"-checkboxes
  categoryAllCheckbox.forEach(option => {
    option.addEventListener('click', function() {
      event.target.parentNode.style.backgroundColor = '#B8D3C0' // fix onclick all checked color

      var allCategories = event.target.parentNode.parentNode.querySelectorAll(
          '.categories input'
        ),
        i
      for (i = 0; i < allCategories.length; ++i) {
        currentSelection = currentSelection.filter(
          value => value !== allCategories[i].value
        )
        if (allCategories[i].checked == true) {
          allCategories[i].click() // .checked = true ---- wont work
        }
      }

      //search for input if field is set
      if (document.getElementById('searchbar').value !== '') {
        document.querySelector('.searchbtn').click()
      }
    })
  })

  // onclick "categories"-checkboxes
  categoryCheckbox.forEach(option => {
    option.addEventListener('change', function() {
      //check which checkbox was clicked
      var checked = event.target.checked
      var checkBoxValue = event.target.value
      console.log(checkBoxValue)
      if (checked == true && !currentSelection.includes(checkBoxValue)) {
        currentSelection.push(checkBoxValue)
        if (
          event.target.parentNode.parentNode.querySelector('.alle input')
            .checked == true
        ) {
          event.target.parentNode.parentNode.querySelector(
            '.alle input'
          ).disabled = false
          event.target.parentNode.parentNode.querySelector(
            '.alle'
          ).style.backgroundColor = 'white' // .checked = false ---- wont work
        }
      } else {
        currentSelection = currentSelection.filter(
          value => value !== checkBoxValue
        )

        var categoryInputs = event.target.parentNode.parentNode.querySelectorAll(
          '.categories input'
        )
        var empty = [].filter.call(categoryInputs, function(el) {
          return !el.checked
        })
        if (categoryInputs.length == empty.length) {
          if (
            event.target.parentNode.parentNode.querySelector('.alle input')
              .checked == false
          ) {
            event.target.parentNode.parentNode
              .querySelector('.alle input')
              .click() // .checked = false ---- wont work
          } else {
            event.target.parentNode.parentNode.querySelector(
              '.alle input'
            ).disabled = true
            event.target.parentNode.parentNode.querySelector(
              '.alle'
            ).style.backgroundColor = '#B8D3C0' // fix onclick all checked color
          }
        }
      }
      //search for input if field is set
      if (document.getElementById('searchbar').value !== '') {
        document.querySelector('.searchbtn').click()
      } else {
        filterAngebote(angeboteVergleichsObjekt, currentSelection, false)
      }
    })
  })

  //expand collapse

  for (let i = 0; i < categoryCheckbox.length; i++) {
    categoryCheckbox[i].addEventListener('click', function() {
      changeCheckBoxColor(this)
    })
  }
} // End-if angebote URL
