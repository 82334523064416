/**
 * External Dependencies
 */

import 'jquery'
import 'slick-carousel'
// Offset html anchor for fixed header

require('./modules/anchor-scroll')

$(document).ready(() => {
  require('./modules/filter')
  // initialize Slick Carousel
  $('.referenzen').slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 4000,
    dots: true,
    arrows: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  })
})

let url = window.location.pathname

/*++++++++++++++++++++++++++++++++++++++++++++++++++++++
 ++  Netzwerke expand/collapse +++++++++++++++++++++++++++++
 +++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

if (
  url == '/Netzwerke/' ||
  url == '/Netzwerke/#netzwerkpartner' ||
  url == '/netzwerke/'
) {
  const btnWeiter = document.querySelectorAll('.np-weiterlesen')

  for (let i = 0; i < btnWeiter.length; i++) {
    btnWeiter[i].addEventListener('click', function() {
      console.log(i)
      this.previousElementSibling.previousElementSibling.classList.toggle(
        'max-height-0'
      )
      this.style.display = 'none'
      this.previousElementSibling.style.display = 'block'
    })
  }

  const closeBtn = document.querySelectorAll('.np-close')
  for (let i = 0; i < closeBtn.length; i++) {
    closeBtn[i].addEventListener('click', function() {
      this.previousElementSibling.classList.toggle('max-height-0')
      console.log(this.previousElementSibling)
      this.style.display = 'none'
      this.nextElementSibling.style.display = 'block'
    })
  }

  const buttons = document.querySelectorAll('.np-kontakt-expander')
  buttons.forEach(btn =>
    btn.addEventListener('click', function() {
      this.nextElementSibling.classList.toggle('max-height-0')
      this.nextElementSibling.classList.toggle('mb-2')
    })
  )

  /*´´
   document.querySelector('.turn-btn').addEventListener('click', function() {
     this.classList.contains('turn-180')
       ? this.classList.toggle('turn180')
       : this.classList.toggle('turn-180')
   })*/
}

/*++++++++++++++++++++++++++++++++++++++++++++++++++++++
 ++  Angebote +++++++++++++++++++++++++++++
 +++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

if (url == '/angebote/') {
  // expand collapse select
  const select = document.querySelectorAll('.select-label')
  select.forEach(select =>
    select.addEventListener('click', function() {
      this.nextElementSibling.classList.toggle('max-height-0')
    })
  )

  //reoload button
  document.querySelector('.reload').addEventListener('click', function() {
    window.location.reload()
    console.log('reload')
  })

  //collapse/expand Angebot
  const weiterlesenBtnNode = document.querySelectorAll('.angebote-weiterlesen')
  const weiterlesenBtn = Array.from(weiterlesenBtnNode)
  for (let i = 0; i < weiterlesenBtn.length; i++) {
    weiterlesenBtn[i].addEventListener('click', function() {
      this.parentNode.classList.add('width-100')
      console.log(this.parentNode)
      this.previousElementSibling.children[1].style.height = '100%'
      this.previousElementSibling.children[1].style.display = 'block'
      this.style.display = 'none'
      this.previousElementSibling.previousElementSibling.children[0].style.display =
        'flex'
      this.previousElementSibling.children[0].children[1].style.display =
        'block'

      this.previousElementSibling.children[0].classList.add('w-66-100')
      this.parentNode.classList.add('angebot-expand')
      this.previousElementSibling.children[0].children[0].style.display =
        'block'
      this.previousElementSibling.children[0].children[1].style.display =
        'block'
      this.previousElementSibling
        .querySelector('h3')
        .classList.add('heading-size-2')
      this.previousElementSibling
        .querySelector('h3')
        .classList.remove('heading-size-3')
      this.parentNode.children[0].style.display = 'none'
      console.log(this.parentNode.children[0])
    })
  }
  const closeBtnNode = document.querySelectorAll('.angebote-schliessen')
  const closeBtn = Array.from(closeBtnNode)
  for (let i = 0; i < closeBtn.length; i++) {
    closeBtn[i].addEventListener('click', function() {
      this.parentNode.parentNode.classList.remove('width-100')
      this.parentNode.nextElementSibling.children[1].style.height = '0'
      this.parentNode.nextElementSibling.children[1].style.display = 'none'
      this.parentNode.nextElementSibling.nextElementSibling.style.display =
        'flex'
      this.style.display = 'none'
      this.parentNode.nextElementSibling.children[0].children[1].style.display =
        'none'
      this.parentNode.nextElementSibling.children[0].classList.remove(
        'w-66-100'
      )
      this.parentNode.classList.remove('angebot-expand')
      this.parentNode.previousElementSibling
      this.parentNode.nextElementSibling
        .querySelector('h3')
        .classList.remove('heading-size-2')
      this.parentNode.nextElementSibling
        .querySelector('h3')
        .classList.add('heading-size-3')
      this.parentNode.previousElementSibling.style.display = 'flex'
    })
  }
}

/* Events expand und collapse, nur aktive auf Events*/
if (url == '/veranstaltungen/') {
  const closeBtn = document.querySelectorAll('.close-btn')
  const mehrErfahren = document.querySelectorAll('.mehr-erfahren')

  for (let i = 0; i < mehrErfahren.length; i++) {
    mehrErfahren[i].addEventListener('click', function() {
      this.parentNode.nextElementSibling.style.maxHeight = '100%'
      this.parentNode.nextElementSibling.style.paddingTop = '3rem'
      this.classList.toggle('hide')
      this.previousElementSibling.classList.toggle('hide')
    })
  }

  for (let i = 0; i < closeBtn.length; i++) {
    closeBtn[i].addEventListener('click', function() {
      this.classList.toggle('hide')
      this.nextElementSibling.classList.toggle('hide')
      this.parentNode.nextElementSibling.style.maxHeight = '0'
      this.parentNode.nextElementSibling.style.paddingTop = '0'
    })
  }

  /*+++ Events Archiv ++++++++++++ */
  document
    .querySelector('.archiv button')
    .addEventListener('click', function() {
      const archiv = document.querySelector('.archived-events')
      this.classList.contains('turn-180')
        ? this.classList.toggle('turn180')
        : this.classList.toggle('turn-180')

      archiv.classList.toggle('max-height-0')
    })
}

/* Sektion Vorteile: expand und collapse, nur aktiv afu der Staertseite und auf Was ist EE?*/
if (url == '/' || url == '/was-ist-ee/') {
  const akkordeon = document.querySelectorAll('.akkordeon')
  for (let i = 0; i < akkordeon.length; i++) {
    akkordeon[i].addEventListener('click', function() {
      this.classList.toggle('hide')
      this.previousElementSibling.style.maxHeight = '100%'
      this.nextElementSibling.classList.toggle('hide')
    })
  }

  const collapse = document.querySelectorAll('.collapse')
  for (let i = 0; i < akkordeon.length; i++) {
    collapse[i].addEventListener('click', function() {
      this.classList.toggle('hide')
      this.previousElementSibling.previousElementSibling.style.maxHeight =
        '329px'
      this.previousElementSibling.classList.toggle('hide')
    })
  }
}

function expandBeitrag() {
  this.classList.toggle('hide')
  this.previousElementSibling.previousElementSibling.style.maxHeight = '100%'
  this.nextElementSibling.classList.toggle('hide')
  this.parentNode.scrollIntoView()
}

/* aktuelles expander */
if (url == '/' || url == '/aktuelles/') {
  const akkordeon = document.querySelectorAll('.aktuelles-weiterlesen')

  for (let i = 0; i < akkordeon.length; i++) {
    akkordeon[i].addEventListener('click', expandBeitrag)
  }

  const collapse = document.querySelectorAll('.aktuelles-collapse')
  for (let i = 0; i < akkordeon.length; i++) {
    collapse[i].addEventListener('click', function() {
      this.classList.toggle('hide')
      this.previousElementSibling.previousElementSibling.previousElementSibling.style.maxHeight =
        '144px'
      this.previousElementSibling.classList.toggle('hide')
      this.parentNode.scrollIntoView()
    })
  }

  const beitrag = document.querySelectorAll('.aktuelles-post')
  const beitragArray = Array.from(beitrag)

  const beitragID = beitragArray.map(post => post.id)

  const hash = window.location.hash
  const hashString = hash.slice(1, hash.length)

  for (let i = 0; i < beitragID.length; i++) {
    if (beitragID[i] == hashString) {
      document.querySelectorAll('.aktuelles-expander')[i].style.maxHeight =
        '100%'
      akkordeon[i].classList.toggle('hide')
      collapse[i].classList.toggle('hide')
    }
  }
}

// Aktuelles Archiv
if (url == '/aktuelles/') {
  document
    .querySelector('.archiv button')
    .addEventListener('click', function() {
      const archiv = document.querySelector('.archived-events')
      this.classList.contains('turn-180')
        ? this.classList.toggle('turn180')
        : this.classList.toggle('turn-180')
      archiv.classList.toggle('max-height-0')
    })
}

/*++++++++++++++++++++++++++++++++++++++++++++++++++++++
 ++ Change Navigationbar upon Scroll ++++++++++++
 +++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

/* nav scrol */
// const nav = document.querySelector(".menu-content");
// const scrollDown = "scroll-down";

window.addEventListener('scroll', () => {
  if (window.innerWidth > 782) {
    const currentScroll = window.pageYOffset
    currentScroll >= 38 ? collapseNav() : extendNav()
  }
})

const menuContent = document.querySelector('.menu-content')
const topNavLow = document.querySelector('.top-nav-low')
const upBtn = document.querySelector('.up-btn')

/* eslint-disable */
/* prettier-ignore */
function collapseNav() {
     menuContent.classList.add('scroll-down')
     topNavLow.classList.remove(
       'mt-7',
       'flex',
       'space-between',
       'section-headline'
     )
     upBtn.classList.add('scroll-top-button')
   }

/* eslint-disable */
/* prettier-ignore */
function extendNav() {
     menuContent.classList.remove('scroll-down')
     topNavLow.classList.add('mt-7', 'flex', 'space-between', 'section-headline')
     upBtn.classList.remove('scroll-top-button')
   }

/* mobile nav */
const burgerMenuToggle = document.querySelector('.burger-menu-toggle')
const navMobile = document.querySelector('.nav-mobile')

burgerMenuToggle.addEventListener('click', function() {
  navMobile.classList.toggle('nav-toggle')
})

navMobile.addEventListener('click', function() {
  navMobile.classList.toggle('nav-toggle')
})

/* Cookies Matomo */
/* eslint-disable */

function getCookie(cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

if (getCookie('wpl_viewed_cookie') == 'yes') {
  if (JSON.parse(getCookie('wpl_user_preference')).analytics == 'yes') {
    enableGoogleAnalytics()
  }
}

window.addEventListener(
  'GdprCookieConsentOnAccept',
  e => {
    console.log(e)
    if (e.detail.wpl_viewed_cookie == 'yes') {
      if (e.detail.wpl_user_preference.analytics == 'yes') {
        enableGoogleAnalytics()
      }
    }
  },
  false
)

function enableGoogleAnalytics() {
  console.log('Matomo analytics')

  var _paq = (window._paq = window._paq || [])
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView'])
  _paq.push(['enableLinkTracking'])
  ;(function() {
    var u = '//wir-unternehmen-was.sh/matomo/'
    _paq.push(['setTrackerUrl', u + 'matomo.php'])
    _paq.push(['setSiteId', '1'])
    var d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0]
    g.type = 'text/javascript'
    g.async = true
    g.src = u + 'matomo.js'
    s.parentNode.insertBefore(g, s)
  })()
}

// checked analytics checkbox
document.getElementById('gdpr_messagebar_body_button_analytics').checked = true
