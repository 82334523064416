/* eslint-disable */
/* prettier-ignore */
function scrollToAnchor(hash) {

    setTimeout(function() { },10)
            var target = $(hash),
            // headerHeight = $(".nav-links").height() + 10; // Get fixed header height
            target = target.length ? target : $('[name=' + hash.slice(1) +']');
            
            if (target.length)
            {
                if (!$('.menu-content').hasClass('scroll-down')) {
                    console.log('top');
                    $('html,body').animate({
                        scrollTop: target.offset().top - 220
                    }, 10);
    
                } else {
    
                    console.log('bottom');
                    $('html,body').animate({
                        scrollTop: target.offset().top - 66
                    }, 10);
    
                
                }
                return false;
            }
       
    }
        
    if(window.location.hash) {
        $(window).load(function() {
            scrollToAnchor(window.location.hash);
        });
    }
        
    $("a[href*=\\#]:not([href=\\#])").click(function(event)
    {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,''))
        {
            scrollToAnchor(this.hash);
            event.preventDefault();
        }
        
    });
    